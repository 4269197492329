import {
  IconCircleMinus,
  IconHome,
  IconClockPause,
  IconPlaneDeparture,
} from "@tabler/icons-react";

export const COMPLIANCE_OVERRIDE_TYPES = {
  INVALIDATE: {
    TEXT: "Invalidate",
    ICON: <IconCircleMinus />,
  },
  AUTHORIZEDWFH: {
    TEXT: "Authorized WFH",
    ICON: <IconHome />,
  },
  OFFICIALBUSINESS: {
    TEXT: "Official Business",
    ICON: <IconPlaneDeparture />,
  },
  PENDINGLEAVEFILING: {
    TEXT: "Pending Leave Filing",
    ICON: <IconClockPause />,
  },
};
