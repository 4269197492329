import RightOffCanvas from "components/ui/offCanvas/rightOffCanvas";
import { Fragment, useEffect, useState } from "react";
import {
  format,
  getWeek,
  getYear,
  isSameYear,
  eachDayOfInterval,
  eachWeekOfInterval,
  endOfWeek,
} from "date-fns";
import {
  IconCircleCheck,
  IconCheck,
  IconXboxX,
  IconX,
  IconCirclePlus,
  IconCircleMinus,
} from "@tabler/icons-react";
import { useSelector, useDispatch } from "react-redux";

import { updateOverrideFormData } from "pages/officeMonitoring/state/reducers";
import { CURRENT_RELEASE_NOTES } from "constants/global";

export const UserRecordsCanvas = ({ selectedUser }) => {
  const user = selectedUser[0] || {};
  const userName = user?.name?.split(" ") || [];
  const complianceRate = parseInt(user.complianceRate) || 0;
  const [groupedRecord, setGroupedRecord] = useState([]);

  const { startDate, endDate } = useSelector(
    (state) => state.dateRangeSelected.value
  );
  const differentYear = !isSameYear(new Date(startDate), new Date(endDate));
  const daysOfEveryWeek = eachWeekOfInterval({
    start: new Date(startDate),
    end: new Date(endDate),
  }).map((startDay) =>
    eachDayOfInterval({ start: new Date(startDay), end: endOfWeek(startDay) })
  );

  useEffect(() => {
    setGroupedRecord(user.compliance || []);
  }, [selectedUser]);

  return (
    <Fragment>
      <RightOffCanvas title="RTO Records">
        <div className="row align-items-center">
          <div className="col-2">
            <span className="avatar avatar-lg me-2">{`${userName[0]?.charAt(
              0
            )}${userName[userName?.length - 1]?.charAt(0)}`}</span>
          </div>
          <div className="col">
            <h3 className="card-title mb-1 d-flex align-items-center">
              {user?.name}

              {user?.isManager ? (
                <span className="badge bg-blue-lt ms-2">Manager</span>
              ) : null}
            </h3>
            {complianceRate === 100 ? (
              <>
                <span className="badge bg-success me-1 "></span>{" "}
                <span className="page-pretitle">2 or more days onsite</span>
              </>
            ) : (
              <>
                {user?.regTemp === "Regular" ? (
                  <span className="badge bg-danger me-1 "></span>
                ) : (
                  <span className="badge bg-gray me-1 "></span>
                )}
                <span className="page-pretitle">Less than 2 days onsite</span>
              </>
            )}

            <div className="mt-2">
              <div className="row g-2 align-items-center">
                <div className="col-auto">{complianceRate} %</div>
                <div className="col">
                  <div className="progress progress">
                    {complianceRate === 100 ? (
                      <div
                        className="progress-bar bg-success"
                        style={{ width: "100%" }}
                        role="progressbar"
                        aria-valuenow="76"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    ) : (
                      <div
                        className="progress-bar bg-danger"
                        style={{ width: `${complianceRate}%` }}
                        role="progressbar"
                        aria-valuenow="76"
                        aria-valuemin="0"
                        aria-valuemax="100"
                      ></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-auto"></div>
        </div>

        <div className="row pt-4">
          <div className="accordion" id="accordion-example">
            <div className="accordion-item border-0 border-bottom ">
              {daysOfEveryWeek.map((days, i) => (
                <UserWeekRecordAccordion
                  key={i}
                  data={groupedRecord}
                  dates={days}
                  differentYear={differentYear}
                  user={user}
                />
              ))}
            </div>
          </div>
        </div>
      </RightOffCanvas>
    </Fragment>
  );
};

const UserWeekRecordAccordion = ({ data = [], dates, differentYear, user }) => {
  const requiredRTODays = 2;
  let complianceCount = 0;

  const overrideNote = `<p>Managers can create attendance overrides for employees who are unable to report to the office due to legitimate reasons such as leaves, illness, emergency, or other special events. </p>
                                  <p class='mb-0'><a
                href='${CURRENT_RELEASE_NOTES}'
                className='text-decoration-underline'
                target='_blank'
              >
                See release notes for more information
              </a></p>`;

  const leaveNote = `<p>Leave records are automatically synced daily at 1 AM for accurate and efficient management. </p>
                                  <p class='mb-0'><a
                href='${CURRENT_RELEASE_NOTES}'
                className='text-decoration-underline'
                target='_blank'
              >
                See release notes for more information
              </a></p>`;

  const facialRecognitionNote = `<p>To ensure accuracy, Facial Recognition logs are automatically synced every 2 hours. </p>
                                  <p class='mb-0'><a
                href='${CURRENT_RELEASE_NOTES}'
                className='text-decoration-underline'
                target='_blank'
              >
                See release notes for more information
              </a></p>`;

  const dateItemList = dates.map((element) => {
    const idx = data.findIndex(
      (f) =>
        new Date(f.date).setHours(0, 0, 0, 0) ===
        new Date(element).setHours(0, 0, 0, 0)
    );
    const item = data.find(
      (f) =>
        new Date(f.date).setHours(0, 0, 0, 0) ===
        new Date(element).setHours(0, 0, 0, 0)
    );
    if (item != null) {
      if (item.facialRecognition != null) {
        complianceCount++;
      } else if (item.leave != null) {
        complianceCount++;
      } else if (item.complianceOverride != null) {
        if (
          item.complianceOverride.type == "Pending Leave Filing" &&
          item.complianceOverride.status != "Expired"
        ) {
          complianceCount++;
        } else if (item.complianceOverride.type == "Authorized WFH") {
          complianceCount++;
        }
      } else if (item.holiday != null) {
        complianceCount++;
      }

      if (item.complianceOverride != null) {
        if (item.complianceOverride.type == "Invalidate") {
          complianceCount--;
        }
      }
    }
    return (
      <DateItem
        key={element}
        data={data}
        element={element}
        idx={idx}
        user={user}
      />
    );
  });

  const weekNumber = getWeek(dates[0]);

  const YearText = () =>
    differentYear ? <small>({getYear(dates[0])})</small> : null;

  if (!weekNumber) return null;

  return (
    <Fragment>
      <h2 className="accordion-header" id={`week-header-${weekNumber}`}>
        <button
          className="accordion-button py-2 ps-2 justify-content-between collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#week-${weekNumber}`}
          aria-expanded="false"
        >
          {complianceCount >= requiredRTODays ? (
            <IconCheck className="icon me-1 text-success" />
          ) : (
            <IconX className="icon me-1 text-danger" />
          )}

          <span>
            Week {weekNumber} <YearText />
            <small className="ps-2 page-pretitle">
              {" "}
              {`(${complianceCount}/7)`}
            </small>
          </span>
        </button>
      </h2>
      <div
        id={`week-${weekNumber}`}
        className="accordion-collapse collapse"
        data-bs-parent="#accordion-example"
      >
        <div className="accordion-body p-0">
          <table className="table card-table table-vcenter">
            <thead>
              <tr>
                <th>Date </th>
                <th className="text-start">
                  Facial Recognition{" "}
                  <button
                    className="form-help ms-1 bg-dark-lt small text-white text-decoration-none"
                    data-bs-title="Facial Recognition"
                    data-bs-toggle="popover"
                    data-bs-placement="left"
                    data-bs-content={facialRecognitionNote}
                    data-bs-html="true"
                    data-bs-trigger="focus"
                    tabIndex="0"
                  >
                    ?
                  </button>
                </th>
                {/* <th className="text-center">Network</th> */}
                <th>
                  Leaves{" "}
                  <button
                    className="form-help ms-1 bg-dark-lt small text-white text-decoration-none"
                    data-bs-title="Leaves"
                    data-bs-toggle="popover"
                    data-bs-placement="left"
                    data-bs-content={leaveNote}
                    data-bs-html="true"
                    data-bs-trigger="focus"
                    tabIndex="0"
                  >
                    ?
                  </button>
                </th>
                <th className="text-start">
                  Override{" "}
                  <button
                    className="form-help ms-1 bg-dark-lt small text-white text-decoration-none"
                    data-bs-title="Office Attendance Override"
                    data-bs-toggle="popover"
                    data-bs-placement="left"
                    data-bs-content={overrideNote}
                    data-bs-html="true"
                    data-bs-trigger="focus"
                    tabIndex="0"
                  >
                    ?
                  </button>
                </th>
              </tr>
            </thead>
            <tbody>{[...dateItemList]}</tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

const DateItem = ({ data, element, idx, user }) => {
  const dispatch = useDispatch();
  const { isAdmin, tmManageable } = useSelector(
    (state) => state.activeUser.value
  );

  useEffect(() => {
    var popoverTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="popover"]')
    );

    // Dispose of previous popover instances
    popoverTriggerList.forEach(function (popoverTriggerEl) {
      var popover = window.bootstrap.Popover.getInstance(popoverTriggerEl);
      if (popover) {
        popover.dispose();
      }
    });

    // Create new popover instances
    popoverTriggerList.forEach(function (popoverTriggerEl) {
      new window.bootstrap.Popover(popoverTriggerEl);
    });

    // Clean up popover instances on component unmount
    return () => {
      popoverTriggerList.forEach(function (popoverTriggerEl) {
        var popover = window.bootstrap.Popover.getInstance(popoverTriggerEl);
        if (popover) {
          popover.dispose();
        }
      });
    };
  }, [data]);

  const handleOnDateClick = (email, date = new Date()) => {
    dispatch(
      updateOverrideFormData({
        id: -1,
        employee: "",
        employees: [email],
        startDate: format(date, "yyyy-MM-dd"),
        endDate: format(date, "yyyy-MM-dd"),
        remarks: "",
        author: "",
        type: "",
      })
    );
  };
  const handleOnEditOverride = (
    email,
    id,
    startDate,
    endDate,
    type,
    status,
    remarks
  ) => {
    dispatch(
      updateOverrideFormData({
        id,
        employees: [],
        employee: email,
        startDate: format(new Date(startDate), "yyyy-MM-dd"),
        endDate: format(new Date(endDate), "yyyy-MM-dd"),
        status,
        remarks,
        author: "",
        type,
      })
    );
  };
  const handleOnRemoveOverride = (id, startDate, endDate) => {
    dispatch(
      updateOverrideFormData({
        id,
        employees: [],
        employee: "",
        startDate,
        endDate,
        remarks: "",
        status: "",
        author: "",
        type: "",
      })
    );
  };
  return (
    <>
      <tr key={element}>
        <td className="d-flex align-items-center">
          <strong>
            <span className="text-primary">
              {format(new Date(element), "MMMM dd, yyyy")}
            </span>
          </strong>
          <small className="px-1">{format(new Date(element), "(eee)")}</small>
          <small className="text-success">
            {idx >= 0 && data[idx].holiday ? "(Holiday)" : ""}
            {idx >= 0 && data[idx].holiday ? (
              <small
                className="form-help ms-1 bg-dark-lt small text-white text-decoration-none"
                data-bs-toggle="popover"
                data-bs-placement="right"
                data-bs-content={data[idx].holiday.remarks}
                data-bs-html="true"
                data-bs-trigger="hover focus"
                tabIndex="0"
              >
                ?
              </small>
            ) : (
              ""
            )}
          </small>
        </td>

        {idx >= 0 && data[idx].facialRecognition ? (
          <td>
            {idx >= 0 &&
            data[idx].complianceOverride &&
            data[idx].complianceOverride.type == "Invalidate" ? (
              <IconCircleMinus
                data-bs-toggle="popover"
                data-bs-placement="right"
                data-bs-content="Invalidated"
                data-bs-html="true"
                data-bs-trigger="hover focus"
                className="icon me-1 text-red"
              />
            ) : (
              <IconCircleCheck className="icon me-1 text-success" />
            )}
            <small className="px-1 small text-muted">
              {format(new Date(data[idx].facialRecognition.date), "hh:mm a")}
            </small>
          </td>
        ) : (
          <td>-</td>
        )}

        {idx >= 0 && data[idx].leave ? (
          <td className="text-start">
            <a
              className="text-decoration-none cursor-pointer"
              data-bs-title={
                idx >= 0 &&
                data[idx].complianceOverride &&
                data[idx].complianceOverride.type == "Invalidate"
                  ? "Leave Summary (Invalidated)"
                  : "Leave Summary"
              }
              data-bs-toggle="popover"
              data-bs-placement="top"
              data-bs-content={`
  <p><strong>Date:</strong> ${format(
    new Date(data[idx].leave.startDate),
    "MMMM dd, yyyy"
  )} ${
                data[idx].leave.startDate !== data[idx].leave.endDate
                  ? " - " +
                    format(new Date(data[idx].leave.endDate), "MMMM dd, yyyy")
                  : ""
              } </p>
  <p><strong>Type:</strong> ${data[idx].leave.type}</p>
  <p><strong>Remarks:</strong> ${data[idx].leave.remarks}</p>`}
              data-bs-html="true"
              data-bs-trigger="hover"
              tabIndex="0"
            >
              {idx >= 0 &&
              data[idx].complianceOverride &&
              data[idx].complianceOverride.type == "Invalidate" ? (
                <IconCircleMinus className="icon me-1 text-red" />
              ) : (
                <IconCircleCheck className="icon me-1 text-success" />
              )}
            </a>
            <span
              className="small mx-1 text-truncate d-inline-block"
              style={{ maxWidth: "100px", verticalAlign: "middle" }}
            >
              {data[idx].leave.type}
            </span>
          </td>
        ) : (
          <td className="text-start">-</td>
        )}

        {idx >= 0 && data[idx].complianceOverride ? (
          <td className="text-start">
            <a
              className="text-decoration-none cursor-pointer"
              data-bs-title="Office Attendance Override Summary"
              data-bs-toggle="popover"
              data-bs-placement="top"
              data-bs-content={`
  <p><strong>Date:</strong> ${format(
    new Date(data[idx].complianceOverride.startDate),
    "MMMM dd, yyyy"
  )} ${
                data[idx].complianceOverride.startDate !==
                data[idx].complianceOverride.endDate
                  ? " - " +
                    format(
                      new Date(data[idx].complianceOverride.endDate),
                      "MMMM dd, yyyy"
                    )
                  : ""
              } </p>
  <p><strong>Type:</strong> ${data[idx].complianceOverride.type}  <span>${
                data[idx].complianceOverride.status === "Expired"
                  ? "(Expired)"
                  : ""
              }</span></p>
  
  <p><strong>Remarks:</strong> ${
    data[idx].complianceOverride.remarks
  }</p><p><strong>Author:</strong> ${data[idx].complianceOverride.author}</p>`}
              data-bs-html="true"
              data-bs-trigger="hover"
              tabIndex="0"
            >
              {data[idx].complianceOverride.type === "Invalidate" ? (
                <IconCircleMinus className="icon  text-danger" />
              ) : data[idx].complianceOverride.status === "Expired" ? (
                <IconCircleMinus className="icon text-muted" />
              ) : (
                <IconCircleCheck className="icon  text-success" />
              )}
            </a>
            {(isAdmin || tmManageable) && (
              <>
                {data[idx].complianceOverride.status == "Active" ? (
                  <>
                    <a
                      className="cursor-pointer small mx-1 "
                      href="#"
                      data-bs-toggle="modal"
                      data-bs-target="#modal-edit-override"
                      onClick={() => {
                        handleOnEditOverride(
                          user.email,
                          data[idx].complianceOverride.complianceOverrideId,
                          data[idx].complianceOverride.startDate,
                          data[idx].complianceOverride.endDate,
                          data[idx].complianceOverride.type,
                          data[idx].complianceOverride.status,
                          data[idx].complianceOverride.remarks
                        );
                      }}
                    >
                      Edit
                    </a>
                    <a
                      className="cursor-pointer small mx-1 text-danger "
                      data-bs-toggle="modal"
                      data-bs-target="#modal-delete-override"
                      onClick={() =>
                        handleOnRemoveOverride(
                          data[idx].complianceOverride.complianceOverrideId,
                          data[idx].complianceOverride.startDate,
                          data[idx].complianceOverride.endDate
                        )
                      }
                    >
                      Delete
                    </a>
                  </>
                ) : data[idx].complianceOverride.status == "Expired" ? (
                  <a
                    className="cursor-pointer small mx-1 text-danger "
                    data-bs-toggle="modal"
                    data-bs-target="#modal-delete-override"
                    onClick={() =>
                      handleOnRemoveOverride(
                        data[idx].complianceOverride.complianceOverrideId,
                        data[idx].complianceOverride.startDate,
                        data[idx].complianceOverride.endDate
                      )
                    }
                  >
                    Delete
                  </a>
                ) : (
                  <></>
                )}
              </>
            )}
          </td>
        ) : (
          <td className="text-start">
            {(isAdmin || tmManageable) && user.regTemp === "Regular" ? (
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#modal-create-override"
                onClick={() => handleOnDateClick(user.email, element)}
                className="cursor-pointer small text-secondary"
              >
                <IconCirclePlus className="icon me-2" />
                Add
              </a>
            ) : (
              "-"
            )}
          </td>
        )}
      </tr>
    </>
  );
};
