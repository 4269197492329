import _ from "lodash";

export const getEmployeesPerDayChartData = (obj) => {
  var dates = [];
  const traverse = (obj) => {
    _.forIn(obj, function (val, key) {
      // if (key === "date") {
      //   dates.push({ date: val.split("T")[0] });
      // }
      if (key === "facialRecognition" && val != null) {
        dates.push({ date: val.date.split("T")[0] });
      }
      if (_.isArray(val)) {
        val.forEach(function (el) {
          if (_.isObject(el)) {
            traverse(el);
          }
        });
      }
      if (_.isObject(key)) {
        traverse(obj[key]);
      }
    });
  };
  traverse(obj);

  var result = _(dates)
    .groupBy("date")
    .map(function (items, date) {
      return { date: date.split("T")[0], count: items.length };
    })
    .value();
  return result;
};
