import { components } from "react-select";

import { COMPLIANCE_OVERRIDE_TYPES } from "constants/officemonitoring";
const { SingleValue, Option } = components;
const EventIcons = ({ type }) => {
  switch (type) {
    //Invalidate
    case COMPLIANCE_OVERRIDE_TYPES.INVALIDATE.TEXT:
      return COMPLIANCE_OVERRIDE_TYPES.INVALIDATE.ICON;
    case COMPLIANCE_OVERRIDE_TYPES.PENDINGLEAVEFILING.TEXT:
      return COMPLIANCE_OVERRIDE_TYPES.PENDINGLEAVEFILING.ICON;
    case COMPLIANCE_OVERRIDE_TYPES.OFFICIALBUSINESS.TEXT:
      return COMPLIANCE_OVERRIDE_TYPES.OFFICIALBUSINESS.ICON;
    default:
      return COMPLIANCE_OVERRIDE_TYPES.AUTHORIZEDWFH.ICON;
  }
};

const IconOption = (props) => (
  <Option {...props}>
    <EventIcons type={props.data.value} /> {props.data.value}
  </Option>
);
const IconSingleValue = (props) => (
  <SingleValue {...props}>
    <EventIcons type={props.data.value} /> {props.data.value}
  </SingleValue>
);

export { IconOption, IconSingleValue };
